import {Components} from 'formiojs';

const FieldComponent = Components.components.field;
const EditGridComponent = Components.components.editgrid;

export const template = {
    form: `<div class="component-product-list-container">
        <button ref="choose-product-btn-{{ctx.component.key}}" class="choose-product-btn" type="button">{{ ctx.t("Choose product") }}</button>
        <div ref="modal-{{ctx.component.key}}" class="modal fade component-product-list-modal" id="cubanproductlist-{{ ctx.component.key }}" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg resizable-dialog">
            <div ref="modal-resizer-{{ctx.component.key}}" class="resizer"><span></span></div>
            <div class="modal-content">
              <div class="modal-header py-0">
                <h5 class="modal-title d-inline-block my-1">{{ ctx.t("Product list") }}</h5>
                <button ref="modal-close-{{ctx.component.key}}" type="button" class="close mt-2" data-dismiss="modal"><span>&times;</span></button>
              </div>
              <div ref="modal-content-{{ctx.component.key}}" class="modal-body py-0">
              </div>
            </div>
          </div>
        </div>
</div>`
};

class CubanProductListEditGrid extends EditGridComponent {
    setValue(value, flags = {}) {
        const ret = super.setValue(value, flags);
        if (this.parent && this.parent.sendData) {
            this.parent.sendData();
        }
        return ret;
    }
}

export default class CubanProductList extends FieldComponent {
    constructor(component, options, data) {
        super(component, options, data);
        component.multiple = true;
        this.iframeUrl = component.iframe_url ? component.iframe_url : GLOBALS.CUBAN_PRODUCT_LIST_URL + component.key;
        this.iframeOrigin = new URL(this.iframeUrl).origin;
        this.debugLoggingActive = GLOBALS.CUBAN_PRODUCT_LIST_DEBUG_LOGGING;
        this.receiveMessage = this.receiveMessage.bind(this);

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.pointerX = null;
        this.resizerClick = this.resizerClick.bind(this);
        this.resizerClickOut = this.resizerClickOut.bind(this);
        this.resizerMove = this.resizerMove.bind(this);
        this.childEditGrid = null;
        this.childEditGridComp = null;
        if (component.components && component.components[0] && component.components[0].type === 'editgrid') {
            if (this.debugLoggingActive) {
                console.log('cubanproductlist editgrid found');
            }
            this.childEditGrid = component.components[0];
            // if (this.childEditGrid.validate) {
            //     component.validate = this.childEditGrid.validate;
            //     component.validate.required = true;
            // }
            // Workaround for TOBE-11806 infinite loop
            this.childEditGrid.validate = null;
            this.editGridProps = (this.childEditGrid.components || []).map(el => el.key);
            this.editgridKeysLookupDict = Object.fromEntries(this.editGridProps.map(key => [key, key.replace(/\d+$/, "")]));
            if (this.debugLoggingActive) {
                console.log('cubanproductlist editgrid mapping:')
                console.log(this.editgridKeysLookupDict);
            }
            this.childEditGridComp = new CubanProductListEditGrid({
                type: 'editgrid',
                key: this.childEditGrid.key,
                components: []
            }, options, data);
            this.childEditGridComp.parent = this;
        }
    }

    static schema() {
        return FieldComponent.schema({
            key: 'cubanproductlist',
            type: 'cubanproductlist',
            multiple: true,
        });
    }

    static cleanUpNamePart(namePart) {
        if (!namePart || $.isEmptyObject(namePart)) {
            return '';
        }

        return namePart.replace(/^-+/, '').replace(/[\.:]$/, '') // remove leading dashes, remove trailing dot, colon
    }

    init() {
        if (this.childEditGridComp) {
            this.childEditGridComp.init();
        }
        return super.init();
    }

    render(children) {
        if (this.debugLoggingActive) {
            console.log('cubanproductlist render');
        }
        return super.render(this.renderTemplate('cubanproductlist', {
            valueList: this.getValue()
        }));
    }

    receiveMessage(e) {
        if (e.origin === this.iframeOrigin && this.iframeElement.contentWindow === e.source && typeof e.data === 'string') {
            if (this.debugLoggingActive) {
                console.log('cubanproductlist receiveMessage');
            }
            const data = JSON.parse(e.data);
            if (!data || data.msg_key !== this.component.key) {
                return;
            }
            if (data.status === 'ready') {
                if (this.debugLoggingActive) {
                    console.log("Channel open");
                }
                this.sendData();
                return;
            }
            if (data.data) {
                data.data = JSON.parse(data.data);
            }
            if (data.status !== 'selected' || !data.data) {
                return;
            }
            this.setValue(data.data);
            this.closeModal();
        }
    }

    sendData() {
        if (this.debugLoggingActive) {
            console.log('cubanproductlist sendData');
        }
        if (!this.iframeElement) {
            return;
        }
        const dataToBeSentToIframe = this.getValue();
        if (this.debugLoggingActive) {
            console.log('sending back data:');
            console.log(dataToBeSentToIframe);
        }
        this.iframeElement.contentWindow.postMessage(dataToBeSentToIframe, this.iframeOrigin);
    }

    openModal() {
        if (!this.modalElement) {
            return
        }
        this.modalElement.classList.add('in');
        this.modalElement.style.display = 'block'
    }

    closeModal() {
        if (!this.modalElement) {
            return
        }
        this.modalElement.classList.remove('in');
        this.modalElement.style.display = 'none';
    }

    resizerClick(event) {
        event.preventDefault();
        document.body.style.cursor = 'col-resize';
        this.pointerX = event.clientX;
    }

    resizerClickOut(event) {
        if (this.pointerX === null) {
            return;
        }
        event.preventDefault();
        this.pointerX = null;
        document.body.style.cursor = null;
    }

    resizerMove(event) {
        if (this.pointerX === null) {
            return;
        }
        const diffX = this.pointerX - event.clientX;
        let width = this.modalElement.children[0].clientWidth + diffX;
        if (width > 0) {
            this.modalElement.children[0].style.width = `${width}px`;
            this.pointerX = event.clientX;
        }
    }

    attach(element) {
        if (this.debugLoggingActive) {
            console.log('cubanproductlist attach');
        }
        let refs = {};
        refs[`choose-product-btn-${this.component.key}`] = 'single';
        refs[`modal-content-${this.component.key}`] = 'single';
        refs[`modal-${this.component.key}`] = 'single';
        refs[`modal-close-${this.component.key}`] = 'single';
        refs[`modal-resizer-${this.component.key}`] = 'single';

        this.loadRefs(element, refs);

        this.modalElement = this.refs[`modal-${this.component.key}`];

        const modalContent = this.refs[`modal-content-${this.component.key}`];
        this.iframeElement = document.createElement("iframe");
        this.iframeElement.style.width = "100%";
        this.iframeElement.style.height = "620px";
        this.iframeElement.style.border = "0";
        this.iframeElement.src = this.iframeUrl;
        modalContent.appendChild(this.iframeElement);

        const chooseProductButton = this.refs[`choose-product-btn-${this.component.key}`];
        chooseProductButton.addEventListener('click', this.openModal);

        const closeButton = this.refs[`modal-close-${this.component.key}`];
        closeButton.addEventListener('click', this.closeModal);

        const modalResizer = this.refs[`modal-resizer-${this.component.key}`];
        document.addEventListener('mouseup', this.resizerClickOut);
        document.addEventListener('mousemove', this.resizerMove);
        document.addEventListener('touchmove', this.resizerMove);
        modalResizer.addEventListener('mousedown', this.resizerClick);
        modalResizer.addEventListener('touchstart', this.resizerClick);
        modalResizer.addEventListener('touchend', this.resizerClickOut);

        window.addEventListener('message', this.receiveMessage);
        if (this.childEditGridComp) {
            const editGridEl = document.createElement('div');
            this.childEditGridComp.attach(editGridEl);
        }
        return super.attach(element);
    }

    detach() {
        if (this.debugLoggingActive) {
            console.log('cubanproductlist detach');
        }
        // Resize listeners are attached to document, which are not automatically cleared, I think?
        document.removeEventListener('mouseup', this.resizerClick);
        document.removeEventListener('mousemove', this.resizerMove);
        document.removeEventListener('touchmove', this.resizerMove);
        window.removeEventListener('message', this.receiveMessage);
        if (this.childEditGridComp) {
            this.childEditGridComp.detach();
        }
        return super.detach();
    }

    getValue() {
        if (this.debugLoggingActive) {
            console.log('cubanproductlist getValue');
        }
        if (this.childEditGrid) {
            if (Array.isArray(this.data[this.childEditGrid.key])) {
                return this.data[this.childEditGrid.key].map(item => {
                    let mappedObj = {};
                    Object.keys(item).forEach(propName => {
                        // Remove number suffixes from keys, so they'd match what's on admin-wip-cuba side
                        mappedObj[propName.replace(/\d+$/, "")] = item[propName]
                    });

                    return mappedObj;
                });
            }

            return [];
        }
        const value = super.getValue();

        return Array.isArray(value) ? value : [];
    }

    setValue(value, flags) {
        if (this.debugLoggingActive) {
            console.log('cubanproductlist setValue');
        }
        if (this.childEditGrid) {
            if (this.debugLoggingActive) {
                console.log('These editgrid keys need to be populated:')
                console.log(this.editGridProps);
                console.log('from these values');
                console.log(value);
            }
            this.data[this.childEditGrid.key] = value.map(item => {
                let mappedObj = {};
                this.editGridProps.forEach(propName => {
                    mappedObj[propName] = item[this.editgridKeysLookupDict[propName]];
                    if (mappedObj[propName] && 'value' in Object.keys(mappedObj[propName]) && mappedObj[propName].value) {
                        mappedObj[propName].value = CubanProductList.cleanUpNamePart(mappedObj[propName].value);
                    } else if (mappedObj[propName] && typeof mappedObj[propName] === 'string') {
                        mappedObj[propName] = CubanProductList.cleanUpNamePart(mappedObj[propName]);
                    }
                });

                return mappedObj;
            });
            if (this.debugLoggingActive) {
                console.log('data cleanup resulted in this input for editgrid:')
                console.log(this.data[this.childEditGrid.key]);
            }
        }

        return super.setValue(value, flags);
    }

    everyComponent (fn, options) {
        if (this.childEditGridComp) {
            fn(this.childEditGridComp, [this.childEditGridComp], 0);
        }
    }
}